'use client';

import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { AuthActionError } from '@/api/auth/error';
import { ReCaptchaAction } from '@/lib/constants';

/*
 * Returns function that will return a recaptcha token to pass through in an
 * API request for verification.
 * Must be wrapped by RecaptchaProvider
 */
export function useReCaptcha(action: ReCaptchaAction) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      throw new AuthActionError('Unable to verify ReCaptcha, please try again');
    }
    return executeRecaptcha(action);
  }, [executeRecaptcha, action]);

  return { getReCaptchaToken: getReCaptchaToken };
}
