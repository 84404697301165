import { useRollbar } from '@rollbar/react';
import { useCallback } from 'react';

import { isAuthError } from '@/api/auth/error';
import { useToasts } from '@/rollbar-ui/contexts/ToastsContext';

/**
 * For error handling that isn't specific to form errors. Will pass through
 * the error message as the toast content if the error is an auth error type
 *
 * If it is not, the error will be reported to rollbar and the fallback
 * message will be used as the toast content
 */
export function useAddAuthErrorToast() {
  const { addToast } = useToasts();
  const rollbar = useRollbar();
  const addErrorToast = useCallback(
    (error: unknown, fallbackMessage: string) => {
      if (!isAuthError(error)) {
        rollbar.error('[AUTH] Received unexpected error in auth flow', {
          error,
        });
      }
      addToast({
        variant: 'error',
        content: isAuthError(error) ? error.message : fallbackMessage,
      });
    },
    [addToast, rollbar]
  );
  return addErrorToast;
}
