import { FC, ReactNode } from 'react';

type Props = {
  className?: string;
  children: ReactNode;
};

export const FormFieldContainer: FC<Props> = ({ children, className }) => (
  <div className={`min-h-[1.25rem] leading-3 pt-1 text-xs ${className}`}>
    {children}
  </div>
);
