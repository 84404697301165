import { FieldApi } from '@tanstack/react-form';

import { FormFieldContainer } from './FormFieldContainer';

export function FormFieldInfo({
  field,
}: {
  field: FieldApi<any, any, any, any>;
}) {
  return (
    <FormFieldContainer>
      {field.state.meta.isTouched && field.state.meta.errors ? (
        <span className="text-red-500">
          {field.state.meta.errors.join(', ')}
        </span>
      ) : null}
    </FormFieldContainer>
  );
}
