import { RECAPTCHA_HEADER_NAME } from '@/lib/constants';

export function isInvalidRequest(response: Response) {
  return [400, 422].includes(response.status);
}

export function addRecaptchaTokenToRequest(
  requestInit: RequestInit,
  reCaptchaToken: string
) {
  return {
    ...requestInit,
    headers: {
      ...requestInit.headers,
      ...getReCaptchaTokenHeaders(reCaptchaToken),
    },
  };
}

export function getReCaptchaTokenHeaders(reCaptchaToken: string) {
  return { [RECAPTCHA_HEADER_NAME]: reCaptchaToken };
}
