import { useSearchParams } from 'next/navigation';

import { analytics } from '@/lib/analytics';

export function useSendMixpanelAttribution() {
  const searchParams = useSearchParams();

  function sendMixpanelAttribution(userId: string) {
    const mixPanelId = searchParams?.get('mid');

    if (mixPanelId && userId) {
      // Make alias call to Segment
      analytics.alias(userId, `$device: ${mixPanelId}`);
    }
  }

  return {
    sendMixpanelAttribution,
  };
}
