import { sentenceCase } from './format';

export const PASSWORD_COMPLEXITY_TEXT =
  'Must contain at least one uppercase letter, one lowercase letter, one number, and one special character (e.g., !@#$%^&*)';

export function isValidEmail(email: string) {
  if (!email.includes('@')) return false;

  const [local, domain] = email.split('@');
  if (!local || !domain) return false;
  if (!domain.includes('.')) return false;

  // Check lengths and forbidden characters
  if (local.length > 64 || domain.length > 255) return false;

  return true;
}

export function isValidEmails(emails: string[]) {
  return emails.every((email) => isValidEmail(email));
}

export function isValidPassword(password: string) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/.test(
    password
  );
}

export function validateIsNotEmpty(fieldName: string) {
  return (value: string) => {
    if (!value) {
      return `${sentenceCase(fieldName)} is required`;
    }
  };
}

interface Validator {
  [key: string]: (value: string) => string | undefined;
}

export const validator: Validator = {
  email: (value: string) => {
    if (!value) {
      return 'Required: Please enter your email';
    }
    if (!isValidEmail(value)) {
      return 'Please enter a valid email';
    }
  },
  password: (value: string) => {
    if (!value) {
      return 'Password is required';
    } else if (8 > value.length || value.length > 64) {
      return 'Password must be between 8 and 64 characters';
    } else if (!isValidPassword(value)) {
      return PASSWORD_COMPLEXITY_TEXT;
    }
  },
  recoveryCode: validateIsNotEmpty('recovery code'),
  totpCode: validateIsNotEmpty('two-factor token'),
  username: validateIsNotEmpty('username'),
  usernameOrEmail: (value: string) => {
    if (!value) {
      return 'Username or email is required';
    }
    if (typeof value !== 'string') {
      return 'Please provide a string input.';
    }
    if (isValidEmail(value)) {
      return undefined;
    }
    if (value.length < 3) {
      return 'Username must be at least 3 characters long.';
    }
    if (!value.match(/^[a-z0-9\._-]+$/)) {
      return 'Username must contain only alphanumeric, underscore or hyphen characters.';
    }
  },
};
